.footer {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  height: 5rem;
  background-color: white;
  justify-items: end;
  padding-left: 10rem;
  width: 100%;
}

.footer div {
  margin-right: 5rem;
}

.number-footer {
  font-weight: 300;
  font-size: 0.75rem;
  color: gray;
}

.title-footer {
  font-weight: 500;
  font-size: 1rem;
}

.border-top {
  border-top: solid black 2px;
}

.smooth {
  position: relative;
  display: inline;
}

.smooth::after {
  transition: all 250ms ease-in;
  content: "";
  position: absolute;
  top: 0;
  margin-top: -2px;
  width: 100%;
  height: 2px;
  background: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms ease-in;
}

.smooth:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}


@media (max-width: 565px) {
  .footer {
    padding-left: 1rem;
    height: 3rem;
  }
  .title-footer {
    font-size: 0.75rem;
    margin-left: 0;
  }
}
