@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,500&display=swap');

*{
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  list-style: none;
}

body{
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.absolute-position{
  position: absolute;
}

.relative-position{
  position: relative;
}

.default{
  cursor: default;
}
